<template>
	<div class="app-container">		
		<el-button type="primary" :loading="buttonloading" class="mt-3 mb-3 float-right" v-if="permissionList.includes(permission.add)" v-b-modal.addRow icon="el-icon-plus">{{$t('button.add')}}</el-button>
		<el-table :data="tableData" v-loading="loading" style="width: 100%;" @expand-change="expandChange" @row-click="expandRow" class="mt-3" ref="tableTest" border fit highlight-current-row>
            <el-table-column v-for="title in ajaxTitles" :prop="title.prop" :label="title.label" :key="title.prop" :min-width="title.width">
                <template slot="header">
                    <p class="search-label">{{title.label}}</p>
                    <b-form-input :placeholder="title.label" @keyup.enter.native="initial" v-model="searchData[title.prop]" @change="saveData()"></b-form-input>
                </template>
            </el-table-column>
            <el-table-column type="expand">
                <template slot-scope="scope">
                    <el-button type="primary" :loading="buttonloading" @click="getEditRow(scope.row.id)" v-if="permissionList.includes(permission.edit)" icon="el-icon-edit">{{$t('button.edit')}}</el-button>
                    <el-button type="warning" :loading="buttonloading" @click="formItem(scope.row.id)" v-if="permissionList.includes(permission.edit)" icon="el-icon-folder-opened">{{$t('menu.front_form_item')}}</el-button>
                    <el-button type="success" :loading="buttonloading" @click="getPreviewRow(scope.row.id)" v-if="permissionList.includes(permission.edit)" icon="el-icon-view">{{$t('button.preview')}}</el-button>
                    <el-button type="danger" :loading="buttonloading" @click="deleteRow(scope.row.id)" v-if="permissionList.includes(permission.delete)" icon="el-icon-delete">{{$t('button.delete')}}</el-button>
                </template>
            </el-table-column>
        </el-table>

		<pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="pagination"/>
		
		<el-tooltip placement="top" :content="$t('general.back_to_top')">
			<backtotop :visibility-height="300" :back-position="50" transition-name="fade" />
		</el-tooltip>

        <b-modal id="addRow" :title="$t('menu.front_form_add')" size="md" hide-footer @hide="clearDataList()">
			<el-form @keyup.enter.native="addRow()" >
                <b-form-group label-cols="12" label-cols-lg="4">
                    <template slot="label">{{$t('admin_general.table_code')}}</template>
                    <b-form-input v-model="dataList.code"></b-form-input>
                </b-form-group>
                <b-form-group label-cols="12" label-cols-lg="4">
                    <template slot="label">{{$t('admin_general.table_sort')}}</template>
                    <el-input-number v-model="dataList.sort" :min="0" class="w-100" :precision="0"></el-input-number>
                </b-form-group>
                <b-form-group label-cols="12" label-cols-lg="4">
                    <template slot="label">{{$t('admin_general.table_status')}}</template>
                    <el-switch v-model="dataList.status" active-value="1" inactive-value="0"> </el-switch>
                </b-form-group>

                <b-form-group label-cols="6" label-cols-lg="4">
                    <template slot="label">{{$t('admin_general.table_security')}}</template>
                    <b-form-input v-model="dataList.security" type="password" :placeholder="$t('msg.msg_security_password')"></b-form-input>
                </b-form-group>

                <el-tabs class="mb-3">
                    <el-tab-pane v-for="item in languageList" :key="item.id" :item="item" :label="item.name">
                        <b-form-group label-cols="12" label-cols-lg="4">
                            <template slot="label">{{$t('admin_general.table_name')}}</template>
                            <b-form-input v-model="dataList.name[item.id]" :disabled="loading"></b-form-input>
                        </b-form-group>
                    </el-tab-pane>
                </el-tabs>
            
			</el-form>
            

			<el-footer align="center" class="element-footer">
				<el-button size="medium" @click="$bvModal.hide('addRow')">{{$t('button.cancel')}}</el-button>
				<el-button size="medium" type="primary" @click="addRow()" :loading="buttonloading">{{$t('button.submit')}}</el-button>
			</el-footer>
        </b-modal>

		<b-modal id="editRow" :title="$t('menu.front_form_edit')" size="md" hide-footer @hide="clearDataList()">
			<el-form @keyup.enter.native="editRow()" >
				<b-form-group label-cols="12" label-cols-lg="4">
                    <template slot="label">{{$t('admin_general.table_code')}}</template>
                    <b-form-input v-model="dataList.code"></b-form-input>
                </b-form-group>
                <b-form-group label-cols="12" label-cols-lg="4">
                    <template slot="label">{{$t('admin_general.table_sort')}}</template>
                    <el-input-number v-model="dataList.sort" :min="0" class="w-100" :precision="0"></el-input-number>
                </b-form-group>
                <b-form-group label-cols="12" label-cols-lg="4">
                    <template slot="label">{{$t('admin_general.table_status')}}</template>
                    <el-switch v-model="dataList.status" active-value="1" inactive-value="0"> </el-switch>
                </b-form-group>

                <b-form-group label-cols="6" label-cols-lg="4">
                    <template slot="label">{{$t('admin_general.table_security')}}</template>
                    <b-form-input v-model="dataList.security" type="password" :placeholder="$t('msg.msg_security_password')"></b-form-input>
                </b-form-group>

                <el-tabs class="mb-3">
                    <el-tab-pane v-for="item in languageList" :key="item.id" :item="item" :label="item.name">
                        <b-form-group label-cols="12" label-cols-lg="4">
                            <template slot="label">{{$t('admin_general.table_name')}}</template>
                            <b-form-input v-model="dataList.name[item.id]" :disabled="loading"></b-form-input>
                        </b-form-group>
                    </el-tab-pane>
                </el-tabs>
			</el-form>

			<el-footer align="center" class="element-footer">
				<el-button size="medium" @click="$bvModal.hide('editRow')">{{$t('button.cancel')}}</el-button>
				<el-button size="medium" type="primary" @click="editRow()" :loading="buttonloading">{{$t('button.submit')}}</el-button>
			</el-footer>
        </b-modal>


		<b-modal id="formItemRow" :title="$t('menu.front_form_item')" hide-footer size="md">
            <el-tabs class="attributeTab" fill>
                <el-tab-pane :label="$t('menu.front_form_item')">
                    <el-table :data="attributeTableData" v-loading="loading" style="width: 100%;" @expand-change="attributeExpandChange" @row-click="attributeExpandRow" class="mt-3" ref="tableAttribute" border fit highlight-current-row>
                        <el-table-column v-for="title in attributeAjaxTitles" :prop="title.prop" :label="title.label" :key="title.prop" :min-width="title.width"></el-table-column>
                        <el-table-column type="expand">
                            <template slot-scope="scope">
                                <el-button type="primary" :loading="buttonloading" @click="getEditFormItemRow(scope.row.id)">{{$t('menu.front_form_item_edit')}}</el-button>
                                <el-button type="danger" :loading="buttonloading" @click="deleteFormItemRow(scope.row.id)">{{$t('menu.front_form_item_delete')}}</el-button>
                                <!-- <el-button type="primary" :loading="buttonloading" @click="getEditRow(scope.row.id)" v-if="permissionList.includes(permission.attributeEdit)">{{$t('menu.tools_package_attribute_edit')}}</el-button>
                                <el-button type="danger" :loading="buttonloading" @click="deleteRow(scope.row.id)" v-if="permissionList.includes(permission.attributeDelete)">{{$t('menu.tools_attribute_delete')}}</el-button> -->
                            </template>
                        </el-table-column>
                    </el-table>

                    <pagination v-show="total > 0" :total="attributeTotal" :page.sync="attributeListQuery.page" :limit.sync="attributeListQuery.limit" @pagination="attributePagination"/>
                </el-tab-pane>

                <el-tab-pane :label="$t('menu.front_form_item_add')">
                    <el-form @keyup.enter.native="addFormItemRow()" >
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_code')}}</template>
							<b-form-input v-model="formItemDataList.code"></b-form-input>
						</b-form-group>
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_sort')}}</template>
							<el-input-number v-model="formItemDataList.sort" :min="0" class="w-100" :precision="0"></el-input-number>
						</b-form-group>
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_rows')}}</template>
							<b-form-input v-model="formItemDataList.rows"></b-form-input>
							<small class="text-red">{{$t('msg.msg_row')}}}</small>
						</b-form-group>
						<b-row>
							<b-col cols="6">
								<b-form-group label-cols="3" label-cols-lg="4">
									<template slot="label">xs</template>
									<b-form-input v-model="formItemDataList.xs"></b-form-input>
								</b-form-group>
								<b-form-group label-cols="3" label-cols-lg="4">
									<template slot="label">md</template>
									<b-form-input v-model="formItemDataList.md"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col cols="6">
								<b-form-group label-cols="3" label-cols-lg="4">
									<template slot="label">sm</template>
									<b-form-input v-model="formItemDataList.sm"></b-form-input>
								</b-form-group>
								<b-form-group label-cols="3" label-cols-lg="4">
									<template slot="label">lg</template>
									<b-form-input v-model="formItemDataList.lg"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_required')}}</template>
							<el-switch v-model="formItemDataList.required" active-value="1" inactive-value="0"> </el-switch>
						</b-form-group>
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_status')}}</template>
							<el-switch v-model="formItemDataList.status" active-value="1" inactive-value="0"> </el-switch>
						</b-form-group>

						<b-form-group label-cols="6" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_security')}}</template>
							<b-form-input v-model="formItemDataList.security" type="password" :placeholder="$t('msg.msg_security_password')"></b-form-input>
						</b-form-group>

						<el-tabs class="mb-3">
							<el-tab-pane v-for="item in languageList" :key="item.id" :item="item" :label="item.name">
								<b-form-group label-cols="12" label-cols-lg="4">
									<template slot="label">{{$t('admin_general.table_name')}}</template>
									<b-form-input v-model="formItemDataList.name[item.id]" :disabled="loading"></b-form-input>
								</b-form-group>
							</el-tab-pane>
						</el-tabs>
					</el-form>
                    <el-footer align="center" class="element-footer">
                        <el-button size="medium" type="primary" @click="addFormItemRow()" :loading="buttonloading">{{$t('button.submit')}}</el-button>
                    </el-footer>
                </el-tab-pane>
            </el-tabs>
        </b-modal>   

		<b-modal id="editFormItemRow" :title="$t('menu.front_form_item_edit')" hide-footer @hide="clearFormItemDataList()">
			<el-form @keyup.enter.native="editFormItemRow()" >
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_code')}}</template>
					<b-form-input v-model="formItemDataList.code"></b-form-input>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_sort')}}</template>
					<el-input-number v-model="formItemDataList.sort" :min="0" class="w-100" :precision="0"></el-input-number>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_rows')}}</template>
					<b-form-input v-model="formItemDataList.rows"></b-form-input>
					<small class="text-red">{{$t('msg.msg_row')}}}</small>
				</b-form-group>
				<b-row>
					<b-col cols="6">
						<b-form-group label-cols="3" label-cols-lg="4">
							<template slot="label">xs</template>
							<b-form-input v-model="formItemDataList.xs"></b-form-input>
						</b-form-group>
						<b-form-group label-cols="3" label-cols-lg="4">
							<template slot="label">md</template>
							<b-form-input v-model="formItemDataList.md"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col cols="6">
						<b-form-group label-cols="3" label-cols-lg="4">
							<template slot="label">sm</template>
							<b-form-input v-model="formItemDataList.sm"></b-form-input>
						</b-form-group>
						<b-form-group label-cols="3" label-cols-lg="4">
							<template slot="label">lg</template>
							<b-form-input v-model="formItemDataList.lg"></b-form-input>
						</b-form-group>
					</b-col>
				</b-row>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_required')}}</template>
					<el-switch v-model="formItemDataList.required" active-value="1" inactive-value="0"> </el-switch>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_status')}}</template>
					<el-switch v-model="formItemDataList.status" active-value="1" inactive-value="0"> </el-switch>
				</b-form-group>

				<b-form-group label-cols="6" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_security')}}</template>
					<b-form-input v-model="formItemDataList.security" type="password" :placeholder="$t('msg.msg_security_password')"></b-form-input>
				</b-form-group>

				<el-tabs class="mb-3">
					<el-tab-pane v-for="item in languageList" :key="item.id" :item="item" :label="item.name">
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_name')}}</template>
							<b-form-input v-model="formItemDataList.name[item.id]" :disabled="loading"></b-form-input>
						</b-form-group>
					</el-tab-pane>
				</el-tabs>
			</el-form>
            <el-footer align="center" class="element-footer">
                <el-button size="medium" type="primary" @click="editFormItemRow()" :loading="buttonloading">{{$t('button.submit')}}</el-button>
            </el-footer>
        </b-modal>

		<b-modal id="previewRow" :title="$t('menu.front_form_preview')" size="lg" >
			<el-tabs class="mb-3">
				<el-tab-pane style="height:auto;" v-for="language in languageList" :key="language.id" :item="language" :label="language.name">
					<el-form label-position="top" class="mt-3">
						<b-row>
							<b-col v-for="(thisForm,index) in formList" :key="index" :class="thisForm.class">
								<b-form-group :id="thisForm.id" :label="thisForm.name[language.id]" :label-for="thisForm.id" >
									<b-form-input  v-if="thisForm.rows == 0" type="text" :placeholder="thisForm.name[language.id]" ></b-form-input>
									<b-form-textarea  v-else  type="text" :placeholder="thisForm.name[language.id]" :rows="thisForm.rows">{{thisForm.name[language.id]}}</b-form-textarea>
								</b-form-group>
							</b-col>
						</b-row>
					</el-form>
				</el-tab-pane>
			</el-tabs>
        </b-modal>

	</div>

</template>

<script>
import {getLocalStorage} from '@/system/store/localstorage';
import {postMethod} from '@/system/model/post/post';
import {getDAES, getEAES} from '@/system/library/security';
import pagination from '@/components/pagination';
import backtotop from '@/components/backtotop';

let searchForm = {
	pagination: 1,
	limit: 10,
	id: '',
	code:'',
	sort:'',
	status:'',
}
			
export default{
	components: { pagination, backtotop },
	inject:['preloader'],
	data(){
		return {
			loading: true,
			buttonloading: false,
			tableData: [],
			total: 0,
			errors: [],
			submitForm: {
				id:'',
				security:'',
			},
			postData: {
				data: '',
				language: '',
			},
			searchData:Object.assign({}, searchForm),
			listQuery: {
				page: 1,
				limit: 10
			},
			ajaxTitles:[{
                prop: "id",
                label: this.$t('admin_general.table_id'),
                width:'50'
            },{
                prop: "code",
                label: this.$t('admin_general.table_code'),
                width:'100'
			},{
                prop: "sort",
                label: this.$t('admin_general.table_sort'),
                width:'60'
			},{
                prop: "status",
                label: this.$t('admin_general.table_status'),
                width:'60'
			}],
			dataList:{
				code:'',
				rows:'',
				required:'',
				sort:'',
                status:'',
                security:'',
                name:[]
			},
			permission:{
                ajaxTable:'NLNEI6',
                add:'2IPXOR',
                preview:'2IPXOR',
                DBadd:'5XIYKK',
                edit:'78DPZ7',
                DBedit:'RAIPIY',
                delete:'J8ZCBH',
                DBdelete:'CTYCN5'
            },
            permissionList:[],
            languageList:[],
            formItemDataList:{
				code:'',
				rows:'',
				required:'',
				sort:'',
				xs:'',
				sm:'',
				md:'',
				lg:'',
                status:'',
                security:'',
                name:[]
			},
			attributeSearchData:{
				pagination: 1,
				limit: 10,
				form_id:''
            },
            attributeTableData: [],
			attributeTotal: 0,
			attributeListQuery: {
				page: 1,
				limit: 10
			},
			attributeAjaxTitles:[{
                prop: "id",
                label: this.$t('admin_general.table_id'),
                width:'50'
            },{
                prop: "code",
                label: this.$t('admin_general.table_code'),
                width:'100'
			},{
                prop: "required",
                label: this.$t('admin_general.table_required'),
                width:'100'
			},{
                prop: "sort",
                label: this.$t('admin_general.table_sort'),
                width:'60'
			},{
                prop: "status",
                label: this.$t('admin_general.table_status'),
                width:'60'
			}],
			form_id:0,
			formList:[]
		}
	}, methods: {
		initial(){
			if(this.permissionList.includes(this.permission.ajaxTable)){
				this.loading = true;
				this.buttonloading = true;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.searchData));
				var result = postMethod('front/form/ajaxTable',this.postData);
				result.then((value) =>{
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						this.tableData = data.datatable.data;
						this.total = parseInt(data.datatable.total_number);
						this.listQuery.page = parseInt(data.datatable.current_pagination);
						this.listQuery.limit = parseInt(data.datatable.limit);
						this.buttonloading = false;
						this.loading = false;
					}
				});
			}
		},clearDataList(){
			this.dataList.code = '';
			this.dataList.sort = 10;
			this.dataList.status = '';
			this.dataList.security = '';
			this.dataList.name = [];
		},addRow(){
			if(this.permissionList.includes(this.permission.DBadd)){
				this.preloader(true);
				this.buttonloading = true;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.dataList));
				var text = '';
				var result = postMethod('front/form/DBadd',this.postData);

				result.then((value) =>{
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						this.$message({
							message: data.returnMsg,
							type: 'success'
						});
						
						this.$bvModal.hide('addRow');
						this.initial();
					}else{					
						data.returnMsg.forEach(function (value){
							text+= value+"<br/>";
						});
						
						this.$method.popupErrorMessage(this,text);
					}
					
					this.buttonloading = false;
					this.preloader(false);
				});
			}
		},getEditRow(id){
			if(this.permissionList.includes(this.permission.edit)){
				this.buttonloading = true;
				this.submitForm.id = id;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.submitForm));
				var result = postMethod('front/form/edit',this.postData);
				result.then((value) =>{
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						this.dataList = data.thisDetail;
						this.$bvModal.show('editRow');
					}
					this.buttonloading = false;
				});
			}
		},editRow(){
			if(this.permissionList.includes(this.permission.DBedit)){
				this.buttonloading = true;
				this.preloader(true);
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.dataList));
				var text = '';
				var result = postMethod('front/form/DBedit',this.postData);

				result.then((value) =>{
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						this.$message({
							message: data.returnMsg,
							type: 'success'
						});
						
						this.$bvModal.hide('editRow');
						this.initial();
					}else{					
						data.returnMsg.forEach(function (value){
							text+= value+"<br/>";
						});
						
						this.$method.popupErrorMessage(this,text);
					}
					
					this.buttonloading = false;
					this.preloader(false);
				});
			}
		},deleteRow(id){
			if(this.permissionList.includes(this.permission.DBdelete)){
				this.buttonloading = true;
				var text = '';
				this.$prompt(this.$t('msg.msg_delete'), this.$t('msg.prompt'), {
					confirmButtonText: this.$t('button.confirm'),
					cancelButtonText: this.$t('button.cancel'),
					inputPlaceholder: this.$t('msg.msg_security_password'),
					inputType: 'password'
				}).then(({ value }) => {
					this.submitForm.id = id;
					this.submitForm.security = value;
					this.postData.data = '';
					this.postData.data = getEAES(JSON.stringify(this.submitForm));
					var result = postMethod('front/form/DBdelete',this.postData);
					result.then((value) =>{
					var data = JSON.parse(getDAES(value.data));
						if(value.valid){
							this.$message({
							type: 'success',
							message: data.returnMsg
							});
							this.buttonloading = false;
							this.initial();
						}else{
							this.errors = data.returnMsg;
							
							this.errors.forEach(function (value){
							text+= value+"<br/>";
							});
							
							this.$method.popupErrorMessage(this, text);
							this.buttonloading = false;
						}
					
					});
				}).catch(() => {
					this.buttonloading = false;          
				});
			}
		},formItem(formID){
			if(this.permissionList.includes(this.permission.edit)){
				this.clearFormItemDataList();
				this.buttonloading = true;
				this.attributeSearchData.form_id = formID;
				this.formID = formID;
                this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.attributeSearchData));
				var result = postMethod('front/formitem/ajaxTable',this.postData);
				result.then((value) =>{
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						this.attributeTableData = data.datatable.data;
                        this.attributeTotal = parseInt(data.datatable.total_number);
                        this.attributeListQuery.page = parseInt(data.datatable.current_pagination);
                        this.attributeListQuery.limit = parseInt(data.datatable.limit);
                        this.buttonloading = false;
                        this.loading = false;
                        this.$bvModal.show('formItemRow');
					}
					self.buttonloading = false;
				});
			}
		},clearFormItemDataList(){
			this.formItemDataList.code = '';
			this.formItemDataList.rows = '';
			this.formItemDataList.required = '';
			this.formItemDataList.sort = 10;
			this.formItemDataList.status = '';
			this.formItemDataList.security = '';
			this.formItemDataList.xs = '';
			this.formItemDataList.sm = '';
			this.formItemDataList.md = '';
			this.formItemDataList.lg = '';
			this.formItemDataList.name = [];
        },addFormItemRow(){
            if(this.permissionList.includes(this.permission.DBadd)){
                var text = '';
				this.buttonloading = true;
                this.postData.data = '';
                this.formItemDataList.form_id = this.formID;
				this.postData.data = getEAES(JSON.stringify(this.formItemDataList));
				var result = postMethod('front/formitem/DBadd',this.postData);
				result.then((value) =>{
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						this.$message({
							message: data.returnMsg,
							type: 'success'
						});
					}else{					
						data.returnMsg.forEach(function (value){
							text+= value+"<br/>";
						});
						
						self.$method.popupErrorMessage(self,text);
					}
					
					this.buttonloading = false;
					this.clearFormItemDataList();
					this.formItem(this.formID);
					this.preloader(false);
				});
			}
        },getEditFormItemRow(id){
			if(this.permissionList.includes(this.permission.edit)){
				this.buttonloading = true;
				this.submitForm.id = id;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.submitForm));
				var result = postMethod('front/formitem/edit',this.postData);
				result.then((value) =>{
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						this.formItemDataList = data.thisDetail;
						this.$bvModal.show('editFormItemRow');
					}
					this.buttonloading = false;
				});
			}
		},editFormItemRow(){
			if(this.permissionList.includes(this.permission.DBedit)){
				this.buttonloading = true;
				this.preloader(true);
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.formItemDataList));
				var text = '';
				var result = postMethod('front/formitem/DBedit',this.postData);

				result.then((value) =>{
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						this.$message({
							message: data.returnMsg,
							type: 'success'
						});
						
						this.$bvModal.hide('editFormItemRow');
						this.formItem(this.formID);
					}else{					
						data.returnMsg.forEach(function (value){
							text+= value+"<br/>";
						});
						
						this.$method.popupErrorMessage(this,text);
					}
					
					this.buttonloading = false;
					this.preloader(false);
				});
			}
		},deleteFormItemRow(id){
			if(this.permissionList.includes(this.permission.DBdelete)){
				this.buttonloading = true;
				var text = '';
				var self = this;
				this.$prompt(this.$t('msg.msg_delete'), this.$t('msg.prompt'), {
					confirmButtonText: this.$t('button.confirm'),
					cancelButtonText: this.$t('button.cancel'),
					inputPlaceholder: this.$t('msg.msg_security_password'),
					inputType: 'password'
				}).then(({ value }) => {
					this.submitForm.id = id;
					this.submitForm.security = value;
					this.postData.data = '';
					this.postData.data = getEAES(JSON.stringify(this.submitForm));
					var result = postMethod('front/formitem/DBdelete',this.postData);
					result.then(function(value){
						var data = JSON.parse(getDAES(value.data));
						if(value.valid){
							self.$message({
								type: 'success',
								message: data.returnMsg
							});
							
							self.attributeRow();
							self.buttonloading = false;
						}else{
							self.errors = data.returnMsg;
							
							self.errors.forEach(function (value){
								text+= value+"<br/>";
							});
							
							self.$method.popupErrorMessage(self, text);
							self.buttonloading = false;
						}
					
					});
				}).catch(() => {
					this.buttonloading = false;          
				});
			}
		},getPreviewRow(id){
			if(this.permissionList.includes(this.permission.edit)){
				this.buttonloading = true;
				this.submitForm.id = id;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.submitForm));
				var result = postMethod('front/form/preview',this.postData);
				result.then((value) =>{
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						this.formList = data.formList;
						this.$bvModal.show('previewRow');
					}
					this.buttonloading = false;
				});
			}
		},pagination(){
			this.searchData.pagination = this.listQuery.page;
			this.searchData.limit = this.listQuery.limit;
			this.initial();
		},expandRow(row){
            this.$refs.tableTest.toggleRowExpansion(row);
        },expandChange(row,expandedRows){
            if(expandedRows.length>1){
                this.$refs.tableTest.toggleRowExpansion(expandedRows[0]);
            }
        },attributePagination(){
            this.attributeSearchData.pagination = this.attributeListQuery.page; 
			this.attributeSearchData.limit = this.attributeListQuery.limit;
			this.attributeRow();
		},attributeExpandRow(row){
            this.$refs.tableAttribute.toggleRowExpansion(row);
        },attributeExpandChange(row,expandedRows){
            if(expandedRows.length>1){
                this.$refs.tableTest.toggleRowExpansion(expandedRows[0]);
            }
        },saveData(){
			searchForm = this.searchData;
		}
	}, created: function(){
		var currentLang = (getLocalStorage('currentLang')) ? getLocalStorage('currentLang') : 'en';
		this.postData.language = currentLang;
		this.permissionList = JSON.parse(getLocalStorage('permissionList'));
        this.languageList = JSON.parse(getLocalStorage('languageList'));
        this.initial();
	}
}
</script>